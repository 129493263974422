<template>
  <div>
    <h1 class="mb-0">{{ $t('auth.sign_up') }}</h1>
    <tab-content id="pills-tabContent-1" class="mt-0">
      <tab-content-item :active="true" id="pills-jwt-fill" aria-labelled-by="pills-jwt-tab-fill">
        <sign-up-form></sign-up-form>
      </tab-content-item>
    </tab-content>
  </div>
</template>
<script>

import SignUpForm from './Forms/SignUpForm'

export default {
  name: 'SignUp',
  components: { SignUpForm },
  data: () => ({})
}
</script>
